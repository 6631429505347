import React, {useEffect, useState} from 'react'; //useState
import {NavLink, useHistory} from "react-router-dom";

// REDUCERS
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {configStatus} from '../../reducers/ConfigReducers'; //setEnvironment
// SERVICES
import {AutoLogIn} from '../../app/accountActions';

// TOOLS
import {LocalStorageManager} from '../../tools/LocalStorageManager';

// TYPES
import {AutoLoginCreds, DashboardSwitchItem} from '../../types/CommonTypes';

// STYLES
import {Col, Row} from "react-bootstrap";

// IMAGES
import GameHistoryIcon from "../../images/dashboard/dashboard-game-history-icon.png";
import {requestData} from "../../services/HTTPService";
import {authStatus} from "../../reducers/AuthReducer";


export function Dashboard() {

    const history = useHistory();

    const config =  useAppSelector(configStatus);
    const dispatch = useAppDispatch()
    const login = useAppSelector(authStatus);
    const localStorage = LocalStorageManager.getInstance();
    const [envsOptions, setEnvOption] = useState([] as Array<DashboardSwitchItem>);
    const [providerEnvironmentVersion, setProviderEnvironmentVersion] = useState("");

    useEffect(() => {

        if ( !config.env.casinoTag)  return;

        if( envsOptions.length === 0) {
            config.casino.map( (casinoKey: any) => {
                casinoKey.providers.map( (providerKey: any) => {

                    let _envName = 'AS_' + casinoKey.tag + '_' + providerKey.tag;
                    let _env: AutoLoginCreds = localStorage.getItem(_envName);

                    if (_env != null ) {

                        let _envInfo = {
                            casinoName: casinoKey.name,
                            providerEnv: providerKey.provider,
                            providerName: providerKey.name,
                            casinoTag: casinoKey.tag,
                            providerTag: providerKey.tag
                        }
                        setEnvOption((envsOptions: any) => [...envsOptions, { creds: _env, info: _envInfo }]);
                    }


                    return true;
                });

                return true;
            });
        }
        const fetchProviderEnvironmentVersion = async () => {
            try {
                let auth = { 'Authorization': 'Bearer ' + login.loginToken }
                const response =
                    await requestData('GET', undefined, `${config.env.url.base}${config.env.url.environmentVersion}`, auth);
                setProviderEnvironmentVersion((await response.json()).version);
            } catch (error) {
                console.log(error);
            }
        }
        fetchProviderEnvironmentVersion();

    },[config.env, config.casino, localStorage, providerEnvironmentVersion, envsOptions.length, login.loginToken]);


    function EnvSwitch(creds: AutoLoginCreds) {
        setEnvOption([]);
        AutoLogIn(dispatch, history, config, {casino: creds.casino, provider: creds.provider} )
    }


    return (
        <>
        { (config.env && config.env.url) &&
        <Row>
            <Col xs="12">
                <div className="dashboard-active-env--container">
                    <div className="dashboard-active-env--header">
                        <h1>Dasboard</h1>
                    </div>
                    <div className="dashboard-active-env--data">
                        <div className="dashboard-env--image">
                            <img className="dashboard-list--image" src={GameHistoryIcon} alt=''/><br/>
                        </div>
                        <div className="dashboard-env--info">
                            <h2>Active enviroment informations:</h2>
                            <strong>Casino name:</strong> { config.env.casinoName } <br/>
                            <strong>Provider name:</strong> { config.env.providerName } <br/>
                            <strong>Enviroment version:</strong> { providerEnvironmentVersion } <br/>
                            <strong>Base URL:</strong> { config.env.url.base }   <br/>
                            <NavLink className="" to={'/game-history/' + config.env.casinoTag + '/' + config.env.providerTag}>
                                <span className="dashboard-list--title" >Browse History</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        }
        <Row>
            <Col xs="4">

                <div className="dashboard-list-env--container">
                    <div className="dashboard-list-env--header">
                        <h5>Available enviroments: </h5>

                    </div>
                    <div className="dashboard-list-env--list">
                        { envsOptions.map( (key: DashboardSwitchItem, index: number) => {
                            return  <div className="dashboard-list-env--list-item" key={index}>
                                        <div className="dashboard-list-env--list-info">
                                            Casino name:  {key.info.casinoName} <br/>
                                            Provider name: {key.info.providerName}
                                        </div>
                                        <div className="dashboard-list-env--list-button">
                                            { (key.creds.casino === config.env.casinoTag && key.creds.provider === config.env.providerTag) ? (
                                                <><span style={{color: 'green', fontWeight:'bold'}}>Current active</span></>
                                            ) : (
                                                <button onClick={() => EnvSwitch(key.creds)}>Switch to</button>
                                            )  }
                                        </div>
                                    </div>
                        })}
                    </div>
                    <div className="dashboard-list-env--change">
                        <a className="" href='/login/'><span className="" >Login to different environment</span></a>
                    </div>
                </div>
            </Col>
        </Row>
        </>
    );
}

