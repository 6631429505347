import React, {useEffect, useReducer} from 'react';
import {NavLink, Redirect, Route, Switch, useHistory} from "react-router-dom"; // Redirect,  RouteComponentProps,   withRouter  useParams
// TYPES
import {AppConfig, MainMenuItems} from './types/CommonTypes'; // localCreds, urlPageParams,
//STYLES
import {Col, Nav, Navbar, Row} from "react-bootstrap";
import "./App.scss";

//IMAGES
import navbarLogo from "../src/images/nav-bar-logo.png";

// PAGES
import {Dashboard} from './pages/dashboard/dashboard';
import {LoginPage} from './pages/login/LoginPage';
import {GameHistoryPage} from 'pages/game-history/gameHistoryPage';

// REDUCERS
import {useAppDispatch, useAppSelector} from './app/hooks';
import {authStatus} from './reducers/AuthReducer'; //AutoLoginAction, logOut,
import {configSet, configStatus} from './reducers/ConfigReducers'; //  setEnvironment, deleteEnvironment
// SERVICES
import PrivateRoute from './services/PrivateRouter';
import {AutoLogIn, LogOut} from './app/accountActions';
import {ToolsPage} from "./pages/tools/tools-page";
import {AppRoles} from "./const/CommonConst";


export default function App(props: {configInit: AppConfig, version: string}) {

  const history = useHistory();
  const login = useAppSelector(authStatus);
  const config =  useAppSelector(configStatus);

  const dispatch = useAppDispatch();

  const [state, setState] = useReducer( (state: { mainMenu: Array<MainMenuItems> }, newState: any) => ({...state, ...newState}), { mainMenu: []} )

  useEffect(() => {

    dispatch(configSet(props.configInit));

    console.log('App Init', config);

    // AUTO LOGIN ON ENTERING PAGE
    // Try catch url params, check local storage for token/creds and try verify token - previous login
    if (!login.userLogged && config.casino.length > 0) {
        AutoLogIn(dispatch, history, config);
    };

    if ( config.env.casinoTag ) {
      const menuItems = [
        { title: 'Dashboard', url: '/dashboard/' + config.env.casinoTag + '/' + config.env.providerTag},
        { title: 'Game History', url: '/game-history/' + config.env.casinoTag + '/' + config.env.providerTag},

      ];
      if(login.roles.includes(AppRoles.manageGameCycles)
          || login.roles.includes(AppRoles.manageBrokenGames)
          || login.roles.includes(AppRoles.saxConnector)) {
        menuItems.push({ title: 'Tools', url: '/tools/' + config.env.casinoTag + '/' + config.env.providerTag});
      }
      setState({mainMenu: menuItems});
    }


  },[config, config.env, config.casino, history, login.userLogged, props.configInit, dispatch, login.roles]); //urlParser , //localStorage

  function UserLogOut(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    evt.preventDefault();
    LogOut(dispatch, config);
  }

  return (
    <div className="App">

        { login.userLogged === true ? (
            <>
              <Navbar expand="lg" className="app-header">
                      <Navbar.Brand>
                        <img src={navbarLogo} alt="Promatic Support"/>
                      </Navbar.Brand>
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                          { state.mainMenu.map( (item: any, key:number) => (
                              <NavLink key={key} className="navbar-link navbar-link--page" to= {item.url}> {item.title} </NavLink>
                            ))
                          }
                        </Nav>
                      </Navbar.Collapse>

                      <div className="navbar-options">
                        <span className="navbar-options--user-name"> Hi, {login.userName}</span>
                        <NavLink to={'/login'} className="navbar-link navbar-link--logout" onClick={(evt)=>UserLogOut(evt)}>Log Out</NavLink>
                      </div>
              </Navbar>

              <div className="container container--app">
                <Row>
                    <Col>
                      <Switch>
                        <Redirect exact from="/login" to={'/dashboard/' + config.env?.casinoTag + '/' + config.env?.providerTag } />
                        <Route path="/dashboard" component={Dashboard}/>
                        <PrivateRoute path="/game-history/:casino?/:provider?" auth={login} component={GameHistoryPage} />
                        <PrivateRoute path="/tools/:casino?/:provider?" allowedRoles={[AppRoles.saxConnector, AppRoles.manageBrokenGames, AppRoles.manageGameCycles]} auth={login} component={ToolsPage} />
                      </Switch>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="container-fluid container--login">
              <Row>
                  <Col>
                    <Switch>
                      <Route path="/login" component={LoginPage}/>
                    </Switch>
                </Col>
              </Row>
            </div>
          )
        }

        <div className="app-footer">
            App Support version: {props.version}
        </div>

    </div>
  );
}

