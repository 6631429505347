import { createSlice, PayloadAction } from '@reduxjs/toolkit'; // PayloadAction // createAsyncThunk
import { RootState } from '../app/store'; // AppThunk

// TYPES
import { ConfigState, EnvBasicInformation } from '../types/CommonTypes';



const initialState: ConfigState = {
      casino: [],
      env: {
        casinoName: '',
        casinoTag: '',
        provider: '',
        providerName: '',
        providerTag: '',
        url:  { 
          base: '',
          cycleDetails: '',
          cycleHistory: '',
          loginApi: '',
          session: '',
          verifyTokenApi:'',
        }
      },
};


export const configService = createSlice({
  name: 'configService',
  initialState,
  reducers: {
    // setEnvironment
    // set object / configuration of choosen options by user in Loginpage
    deleteEnvironment: (state) => {
      state.env = initialState.env;
    },
    setEnvironment: (state, action: PayloadAction<EnvBasicInformation>) => {
      state.env = action.payload;
    },
    configSet: (state, action: PayloadAction<ConfigState>) => { 
        state.casino = action.payload.casino
    },
  }
});

export const { configSet, setEnvironment, deleteEnvironment } = configService.actions;

export const configStatus = (state: RootState) => state.config;

export default configService.reducer;
