import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authService from '../reducers/AuthReducer';
import configService from '../reducers/ConfigReducers';

export const store = configureStore({
  reducer: {
    auth: authService,
    config: configService
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
