import React, {Suspense } from 'react'; //useState lazy useReducer

// REDUCERS
import { useAppSelector } from '../../app/hooks';
import { configStatus } from '../../reducers/ConfigReducers';

// MODULES
import { ErrorBoundary } from "./errorBoundary";


export function GameHistoryPage() {

  const config =  useAppSelector(configStatus);
  const GameHistory = React.lazy(() => import('./providers/' + config.env?.provider +'/GameHistory'));

  return (
    <>
      <div className="section-container section-search">
          <div className="section-header">
              <h2>FIND</h2>
              <div className="env_details">
                  <span><strong>Environment</strong>: {config.env?.casinoName}</span>
                  <span><strong>Provider</strong>: {config.env?.providerName}</span>
                  <span><strong>BaseURL</strong>: {config.env?.url?.base}</span>
              </div>
          </div>

          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <GameHistory/>
            </Suspense>
          </ErrorBoundary>
      </div>
    </>
  );
}

