import {Alert, Button, Col, FormLabel, Row} from "react-bootstrap";
import React from "react";
import ReactJson from "react-json-view";
import {useAppSelector} from "../../../app/hooks";
import {authStatus} from "../../../reducers/AuthReducer";
import {requestData} from "../../../services/HTTPService";
import {configStatus} from "../../../reducers/ConfigReducers";

export const CreateBrokenGame = () => {
    const [sessionId, setSessionId] = React.useState<string>('');
    const [response, setResponse] = React.useState<{ isError: boolean, data: any } | undefined>(undefined);
    const login = useAppSelector(authStatus);
    const config =  useAppSelector(configStatus);

    const createBrokenGame = async () => {
        let auth = { 'Authorization': 'Bearer ' + login.loginToken }
        try {
            const response =
                await requestData('POST', undefined, `${config.env.url.base}${config.env.url.brokenGame}/${sessionId}`, auth);
            if(response.ok){
                setResponse({
                    data: await response.json(),
                    isError: false
                });
            } else {
                setResponse({
                    data: await response.json(),
                    isError: true
                });
            }
        } catch (error) {
            console.error(error.error)
        }
    }

    const fetchBrokenaGame = async () => {
        let auth = { 'Authorization': 'Bearer ' + login.loginToken }
        try {
            const response =
                await requestData('GET', undefined, `${config.env.url.base}${config.env.url.brokenGame}/${sessionId}`, auth);
            if(response.ok){
                setResponse({
                    data: await response.json(),
                    isError: false
                });
            } else {
                setResponse({
                    data: await response.json(),
                    isError: true
                });
            }
        } catch (error) {
            console.error(error.error)
        }
    }

    return (
        <>
            <Row>
                <Col lg={8}>
                    <div className={'m-3'}>
                        <div>
                            <FormLabel htmlFor="basic-url">Session id</FormLabel>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Session id"
                            onChange={(e) => setSessionId(e.target.value)}
                            value={sessionId}
                        />
                        <Row>
                            <Col xl={8} className={'mt-3'}>
                                <Button className={'me-3 btn-primary'} onClick={() => fetchBrokenaGame()}>Fetch broken game</Button>
                                <Button className={'me-3 btn-warning'} onClick={() => createBrokenGame()}>Create broken game</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {response && (response.isError
                ?
                <Row className={'justify-content-center'}>
                    <Col lg={10}>
                        <Alert variant={'danger'}>
                            <Alert.Heading>Error</Alert.Heading>
                            {response?.data.message}
                        </Alert>
                    </Col>
                </Row>
                :
                <Row className={'justify-content-center'}>
                    <Col lg={10}>
                        <Alert variant={'success'}>
                            <Alert.Heading>Success</Alert.Heading>
                            <ReactJson src={response?.data} name={'Response'} displayDataTypes={false}/>
                        </Alert>
                    </Col>
                </Row>
            )}
        </>
    )

}
