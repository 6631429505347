export const saxRequest = {
    getLogin: (user: string, password: string) =>
        `action=LOGIN&user=${user}&pwd=${password}&amount=-1&allgames=0&usejp=0`,
    getSelgame: (authtoken: string, gameId?: string) =>
        `action=SELGAME&authtoken=${authtoken}&gameid=${gameId ? gameId : ""}`,
    getQuitgame: (authtoken: string) =>
        `action=QUITGAME&authtoken=${authtoken}`,
    getLogout: (authtoken: string) => `action=LOGOUT&authtoken=${authtoken}`,
    getCheck: (authtoken: string) =>
        `action=CHECK&authtoken=${authtoken ? authtoken : ""}&amsg=1`,
    getSpin: (authtoken: string, bet: number) =>
        `action=GA_SPIN&authtoken=${authtoken}&bet=${bet}`,
    getTake: (authtoken: string, rno: number) =>
        `action=GA_TAKE&authtoken=${authtoken}&rno=${rno}`,
    getGamble: (authtoken: string, bet: number, rno: number) =>
        `action=GA_GAMBLE&authtoken=${authtoken}&amount=${bet}&choice=2&rno=${rno}`,
}
