export default class UrlParser {

    private static instance: UrlParser;

    static getInstance(): UrlParser {
        if (!UrlParser.instance) {
          UrlParser.instance = new UrlParser();
        }
        return UrlParser.instance;
    }

    createSearch(params: any, exception?: Array<string>, defaultValue? : {[key:string]: any}) {
        let _params = '?';
        for (let key in params) {
             if (params[key].length === 0 || (exception !== undefined && exception?.includes(key)) ) continue;

             let _param = (key === 'searchFrom' || key === 'searchTo') 
             ? (typeof params[key] == 'string') ?  params[key] : new Date(params[key]).toISOString() // .utc();
             : params[key];

             if (defaultValue && key in defaultValue) {
                _param = defaultValue[key];
             }

            _params = _params + key + '=' + _param + '&'; 
        }
        _params = _params.slice(0, -1)
        return _params;
    };

    deconstructParamString(url: string) {

            if (url.length === 0 ) return false;

            let pairs = url.substring(1).split('&');
            let params = {} as any;

            pairs.forEach( (value: string, index: number)=>{
                let _item = value.split('=');

                if (_item[0] === 'searchFrom' || _item[0] === 'searchTo') {
                    params[_item[0]] = Date.parse(_item[1]);
                } else {
                    params[_item[0]] = _item[1];
                }
            });

            return params;
    }

    extractPageGrups(url: any) {
        const regex = /\/(?<page>.+)\/(?<casino>.+)\/(?<provider>[^?]+)/;
        const groups = url.match(regex);
        return groups?.groups;
    }

 }

