
// import { useAppSelector, useAppDispatch } from './hooks';
import { AutoLoginAction, logOut, LoginAction } from '../reducers/AuthReducer'; // authStatus
import { setEnvironment, deleteEnvironment } from '../reducers/ConfigReducers'; // configSet, configStatus, 

import { ThunkDispatch, AnyAction, Dispatch } from '@reduxjs/toolkit';
import { History } from 'history';

// TYPES
import { LocalCreds, UrlPageParams, AppConfig, AuthState, ConfigState } from '../types/CommonTypes'


// TOOLS
import UrlParser from '../tools/UrlParser';
import { LocalStorageManager } from '../tools/LocalStorageManager';
import { ParseConfigData } from '../tools/DataParsers';


const urlParser = UrlParser.getInstance();
const localStorage = LocalStorageManager.getInstance();



export function LogIn (
    dispatch: ThunkDispatch<{ auth: AuthState; config: ConfigState; }, null, AnyAction> & ThunkDispatch<{ auth: AuthState; config: ConfigState; }, undefined, AnyAction> & Dispatch<AnyAction>,
    config: AppConfig,
    target: {casino: string, provider: string},
    userCreds: {user: string, pwd: string }) {

        let _environment = ParseConfigData(config.casino, target.casino, target.provider);

        dispatch(setEnvironment(_environment));
        dispatch(LoginAction({ ...userCreds, ...target, url: _environment.url.base + _environment.url.loginApi })).then( (data: any) => {
                
            if (data.payload?.loginData?.provider !== 'scaletech' && (data.payload === undefined || (data.payload.response.login === 'error'))) return true;

            localStorage.setItem(
                'AS_' + data.payload.loginData.casino + '_' + data.payload.loginData.provider, 
                { 
                    user: data.payload.loginData.user, 
                    casino: data.payload.loginData.casino,
                    provider: data.payload.loginData.provider,
                    token: data.payload.response.token
                }
            );

        });

};

export function AutoLogIn(
    dispatch: ThunkDispatch<{ auth: AuthState; config: ConfigState; }, null, AnyAction> & ThunkDispatch<{ auth: AuthState; config: ConfigState; }, undefined, AnyAction> & Dispatch<AnyAction>, 
    history: History<unknown>,
    config: AppConfig,
    target?: {casino: string, provider: string} ) {

        let params: UrlPageParams = urlParser.extractPageGrups(history.location.pathname);
        let localCreds: LocalCreds = (target) ? localStorage.getItem('AS_' + target.casino + '_' + target.provider ) : localStorage.getItem('AS_' + params?.casino + '_' + params?.provider );

        if (params && localCreds) {

            let _environment = ParseConfigData(config.casino, localCreds.casino, localCreds.provider);
            if (_environment) {

                dispatch(AutoLoginAction( {user:localCreds.user, token: localCreds.token, url: _environment.url.base + _environment.url.verifyTokenApi, casino:localCreds.casino, provider:localCreds.provider  })).then( (data: any)=> {

                    if ( data.payload.response.login === 'success') {
                        dispatch(setEnvironment(_environment));
                        if (target) history.push('/dashboard/' + target.casino + '/' + target.provider);
                    } else {

                        let _cleanEnv =  ParseConfigData([], '', '');

                        
                        history.push('/login/');
                        dispatch(setEnvironment(_cleanEnv));
                       
                    }
                });
                
            }
        
        } else {
            history.push("/login");
        }  
}


export function LogOut (
    dispatch: ThunkDispatch<{ auth: AuthState; config: ConfigState; }, null, AnyAction> & ThunkDispatch<{ auth: AuthState; config: ConfigState; }, undefined, AnyAction> & Dispatch<AnyAction>,
    config: AppConfig,
    ) {

    dispatch(logOut())
    dispatch(deleteEnvironment());
    localStorage.removeItem('AS_' + config.env?.casinoTag + '_' + config.env?.providerTag );
}