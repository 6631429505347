import {SaxConnector} from "./sub-pages/sax-connector";
import {Tab, Tabs} from "react-bootstrap";
import {CreateBrokenGame} from "./sub-pages/create-broken-game";
import {ResolveGameCycle} from "./sub-pages/resolve-game-cycle";
import {useAppSelector} from "../../app/hooks";
import {authStatus} from "../../reducers/AuthReducer";
import {AppRoles} from "../../const/CommonConst";

export const ToolsPage = () => {
    const login = useAppSelector(authStatus);

    const getDefTab = (): string => {
        if(login.roles.includes(AppRoles.saxConnector)) return 'sax-connector';
        if(login.roles.includes(AppRoles.manageBrokenGames)) return 'broken-games';
        if(login.roles.includes(AppRoles.manageGameCycles)) return 'game-cycle';
        throw new Error('No tab available');
    }

    return (
        <Tabs fill defaultActiveKey={getDefTab()}>
            {login.roles.includes(AppRoles.saxConnector) && (
                <Tab eventKey="sax-connector" title={<b>Sax connector</b>}>
                    <SaxConnector />
                </Tab>
            )}
            {login.roles.includes(AppRoles.manageBrokenGames) && (
                <Tab eventKey="broken-games" title={<b>Broken game</b>}>
                    <CreateBrokenGame />
                </Tab>
            )}
            {login.roles.includes(AppRoles.manageGameCycles) && (
                <Tab eventKey="game-cycle" title={<b>Game cycle</b>}>
                    <ResolveGameCycle />
                </Tab>
            )}
        </Tabs>
    )
}
