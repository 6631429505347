import {Alert, Button, Col, FormGroup, FormLabel, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import React from "react";
import {requestData} from "../../../services/HTTPService";
import {useAppSelector} from "../../../app/hooks";
import {authStatus} from "../../../reducers/AuthReducer";
import ReactJson from "react-json-view";
import {configStatus} from "../../../reducers/ConfigReducers";
import {ConfirmationDialog} from "../confirmation-dialog";

export const ResolveGameCycle = () => {
    const [gameCycleId, setGameCycleId] = React.useState<string>();
    const [gameCycleWin, setGameCycleWin] = React.useState<number>(0);
    const [originalTransactionId, setOriginalTransactionId] = React.useState("");
    const [originalTransactionDate, setOriginalTransactionDate] = React.useState("");
    const [response, setResponse] = React.useState<{ isError: boolean, data: any } | undefined>(undefined);
    const [showCancelGameCycleDialog, setShowCancelGameCycleDialog] = React.useState<boolean>(false);
    const [showEndGameCycleDialog, setShowEndGameCycleDialog] = React.useState<boolean>(false);
    const login = useAppSelector(authStatus);
    const config =  useAppSelector(configStatus);

    const cancelGameCycle = async () => {
        let auth = { 'Authorization': 'Bearer ' + login.loginToken }
        try {
            const response =
                await requestData('PUT', {originalTransactionId: originalTransactionId, originalTransactionDate: originalTransactionDate}, `${config.env.url.base}${config.env.url.gameCycle}/${gameCycleId}/cancel`, auth, true);
            handleResponse(response);
        } catch (error) {
            console.error(error)
        }
    }
    const fetchGameCycle = async () => {
        let auth = { 'Authorization': 'Bearer ' + login.loginToken }
        try {
            const response =
                await requestData('GET', undefined, `${config.env.url.base}${config.env.url.gameCycle}/${gameCycleId}`, auth);
            handleResponse(response);
        } catch (error) {
            console.error(error)
        }
    }
    const endGameCycle = async () => {
        let auth = { 'Authorization': 'Bearer ' + login.loginToken }
        try {
            const response =
                await requestData('PUT', {win: gameCycleWin}, `${config.env.url.base}${config.env.url.gameCycle}/${gameCycleId}/end`, auth, true);
            handleResponse(response);
        } catch (error) {
            console.error(error)
        }
    }

    const handleResponse = async (response: Response) => {
        if(response.ok){
            setResponse({
                data: await response.json(),
                isError: false
            });
        } else {
            setResponse({
                data: await response.json(),
                isError: true
            });
        }
    }

    return (
        <>
            <ConfirmationDialog
                visible={showCancelGameCycleDialog}
                header={'Canceling game cycle'}
                message={
                    <>
                        This operation cannot be undone. If you are sure you want to cancel this game cycle type original transaction id and the date at which it was sent.
                        <FormGroup className={'mt-2'}>
                            <OverlayTrigger placement={'right'} overlay={
                                <Tooltip>
                                    Can be found in original request to Playtech as 'transId'
                                </Tooltip>
                            }>
                                <FormLabel>Original transaction id</FormLabel>
                            </OverlayTrigger>
                            <input
                                type="text"
                                required={true}
                                className="form-control"
                                placeholder="Original transaction id"
                                onChange={(e) => setOriginalTransactionId(e.target.value)}
                                value={originalTransactionId}
                            />
                        </FormGroup>

                        <FormGroup className={'mt-2'}>
                            <OverlayTrigger placement={'right'} overlay={
                                <Tooltip>
                                    Can be found in original request to Playtech as 'transDateTime'
                                </Tooltip>
                            }>
                                <FormLabel>Original transaction date</FormLabel>
                            </OverlayTrigger>

                            <input
                                type="text"
                                required={true}
                                className="form-control"
                                placeholder="Original transaction date"
                                onChange={(e) => setOriginalTransactionDate(e.target.value)}
                                value={originalTransactionDate}
                            />
                        </FormGroup>
                    </>
                }
                onHide={() => setShowCancelGameCycleDialog(false)}
                accept={() => {
                    setShowCancelGameCycleDialog(false)
                    cancelGameCycle();
                }}
                reject={() => setShowCancelGameCycleDialog(false)}
                cancelButtonText={'Cancel'}
                acceptButtonText={'Cancel game cycle'}
            />
            <ConfirmationDialog
                visible={showEndGameCycleDialog}
                header={'Ending game cycle'}
                message={
                    <>
                        This operation cannot be undone. If you are sure you want to end this game cycle type the amount of money you want to add to the players balance.
                        <input
                            type="number"
                            defaultValue={0}
                            min={0}
                            max={10000000}
                            className="m-2 form-control"
                            placeholder="Game cycle win"
                            onChange={(e) => setGameCycleWin(parseInt(e.target.value))}
                            value={gameCycleWin}
                        />
                    </>
                }
                onHide={() => setShowEndGameCycleDialog(false)}
                accept={() => {
                    setShowEndGameCycleDialog(false)
                    endGameCycle();
                }}
                reject={() => setShowEndGameCycleDialog(false)}
                cancelButtonText={'Cancel'}
                acceptButtonText={'End game cycle'}
            />
            <Row>
                <Col lg={8}>
                    <div className={'m-3'}>
                        <div>
                            <FormLabel htmlFor="basic-url">Game cycle id</FormLabel>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Game cycle id"
                            onChange={(e) => setGameCycleId(e.target.value)}
                            value={gameCycleId}
                        />
                        <Row>
                            <Col xl={8} className={'mt-3'}>
                                <Button className={'me-2 btn-primary'} onClick={() => fetchGameCycle()}>Fetch game cycle</Button>
                                <Button className={'me-2 btn-warning'} onClick={() => setShowCancelGameCycleDialog(true)}>Cancel game cycle</Button>
                                <Button className={'btn-warning'} onClick={() => setShowEndGameCycleDialog(true)}>End game cycle</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {response && (response.isError
                    ?
                    <Row className={'justify-content-center'}>
                        <Col lg={10}>
                            <Alert variant={'danger'}>
                                <Alert.Heading>Error</Alert.Heading>
                                {response?.data.message}
                            </Alert>
                        </Col>
                    </Row>
                    :
                    <Row className={'justify-content-center'}>
                        <Col lg={10}>
                            <Alert variant={'success'}>
                                <Alert.Heading>Success</Alert.Heading>
                                <ReactJson src={response?.data} name={'Response'} displayDataTypes={false}/>
                            </Alert>
                        </Col>
                    </Row>
            )}
        </>


    )
}
