// TYPES
import { CasinoData, EnvBasicInformation, EnvCasinoBasicInformation } from '../types/CommonTypes'; // ProvidersData

// CONST
import { EnvBasicDefaultInformation } from '../const/CommonConst';

export function ParseConfigData(casino: Array<CasinoData>, targetCasino: string, targetProvider: string) {

    let _environment = {} as EnvBasicInformation;

    if (casino.length === 0) {
        _environment = EnvBasicDefaultInformation;
        return _environment;
    }

    casino.forEach( (key: EnvCasinoBasicInformation, i: number) => {

        if (key.tag === targetCasino) {

            let _providers = casino[i].providers.find( (key: { tag: string; })=>  key.tag === targetProvider);

            _environment.casinoName = casino[i].name;
            _environment.casinoTag = casino[i].tag;
            _environment.providerName =  (_providers === undefined) ? '' : _providers.name;
            _environment.provider =  (_providers === undefined) ? '' : _providers.provider;
            _environment.providerTag = (_providers === undefined) ? '' : _providers.tag;
            _environment.url = {
                base: '',
                cycleDetails: '',
                cycleHistory: '',
                loginApi: '',
                session: '',
                verifyTokenApi: '',
            }

            for (let key in _providers) {
                if (key === 'name' || key === 'tag' || key === 'provider') continue;
                _environment.url[key] = _providers[key];
            }
        }
    });

    return _environment;

}