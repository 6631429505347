// REDUCERS
import {Redirect, Route, RouteProps,} from 'react-router-dom';
import {AuthState} from "../types/CommonTypes";

interface PrivateRouteProps extends RouteProps {
  component: any
  auth: AuthState;
  allowedRoles?: Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;

    let auth = {...rest}.auth;
    const hasAccess = props.allowedRoles ? props.allowedRoles.some(role => auth.roles.includes(role)) : true;

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                hasAccess ? (
                    <Component {...routeProps} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;
