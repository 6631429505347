import React from 'react'; //useState

export class ErrorBoundary extends React.Component<{},{hasError: boolean}> {
    constructor(props: any) {
      super(props);
      this.state = { 
          hasError: false 
        };
    }
  
    static getDerivedStateFromError(error: any) {
      return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <div>Missing proiders module or wrong app configuration </div>;
      }
  
      return this.props.children; 
    }
  }