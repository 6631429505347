import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'; //PayloadAction
import {RootState} from '../app/store'; // AppThunk
// TYPES
import {AuthState, AutoLoginCreds, JwtDecodedToken, LoginApiResponse, UserLoginCreds} from '../types/CommonTypes';

// SERVICES
import {LoginApi, TokenVerifyApi} from '../services/HTTPService';

// TOOLS
//import { LocalStorageManager } from '../tools/LocalStorageManager';
//const localStorage =  LocalStorageManager.getInstance();

const initialState: AuthState = {
    userLogged: false,
    userName: '',
    status: 'idle',
    error: false,
    errorMessage: '',
    loginToken: '',
    roles: [],
};

export const parseJwt = (token: string): JwtDecodedToken => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

export const LoginAction = createAsyncThunk(
    'userLogin/fetchLogin',
    async (data: UserLoginCreds) => {
        const response = await LoginApi(data);
        return { loginData: data, response: (response as LoginApiResponse) };
    }
);

export const AutoLoginAction = createAsyncThunk(
  'userLogin/fetchAutoLogin',
  async (data: AutoLoginCreds) => {
      const response = await TokenVerifyApi(data);
      //console.log('response!!!', response);
      return { loginData: data, response: response as {login: string}};
  }
);


export const authService = createSlice({
  name: 'authService',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    logOut: (state) => {
        // console.log('logout dispatch');
        state.userLogged = false;
        state.userName = '';
        state.loginToken = '';
        state.errorMessage = '';
        state.error = false;
        state.roles = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AutoLoginAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(AutoLoginAction.fulfilled, (state, action) => {

        //console.log('AutoLoginAction fulfilled', action.payload);
          if(action.payload.loginData.token) {
              const jwt = parseJwt(action.payload.loginData.token);
              state.roles = jwt.authorities.map((authority) => authority.authority);
          }
        state.status = 'idle';
        state.userName = action.payload.loginData.user;
        state.userLogged = (action.payload.response.login  === 'success') ? true : false;
        state.loginToken = action.payload.loginData.token;


        //  mock
        if (action.payload.loginData.provider === 'scaletech') {
          state.userLogged = true;
          state.loginToken = '1165a8574c89a1a65c5020ea';
        }
        // end of mock

        return  state;
      })
      .addCase(LoginAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(LoginAction.fulfilled, (state, action) => {

        console.log('login user by', action.payload);

        if(action.payload.response.token) {
            const jwt = parseJwt(action.payload.response.token);
            state.roles = jwt.authorities.map((authority) => authority.authority);
        }

        state.status = 'idle';
        state.userLogged = (action.payload.response.login  === 'success') ? true : false;
        state.userName = action.payload.loginData.user;

        state.loginToken = (action.payload.response.login  === 'success') ? action.payload.response.token : '';
        state.error = (action.payload.response.login  === 'error') ? true : false;
        state.errorMessage = (action.payload.response.login  === 'error') ? action.payload.response.msg : '';

        // mock
        if (action.payload.loginData.provider === 'scaletech') {
            state.userLogged = true;
            state.loginToken = '1165a8574c89a1a65c5020ea';
        }
        // end of mock

        return state;
      })
      .addCase(LoginAction.rejected, (state, action) => {

        state.status = 'idle';
        state.error = true;
        state.errorMessage = 'Backend connection problems';

        return state;
      });
  },
});

export const { logOut } = authService.actions;

export const authStatus = (state: RootState) => state.auth;

export default authService.reducer;
