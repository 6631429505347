import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

//TYPES
import { AppConfig } from "./types/CommonTypes";

import App from './App';
import { store } from './app/store';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import * as version from "../package.json";

declare global {
  interface Window {
    APPConfig: AppConfig
  }
}

if (process.env.REACT_APP_CONSOLE_LOG === 'false') {
  console.log = () => {}
  console.debug = () => {}
}


let config = (window as Window).APPConfig;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
      <Router>
        <App configInit={config} version={version.version} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
