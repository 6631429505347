import React, {ReactNode} from "react";
import {Button, Modal} from "react-bootstrap";

export const ConfirmationDialog = (props: {
    visible: boolean;
    header: string;
    message: ReactNode;
    onHide: () => void;
    accept: () => void;
    reject: () => void;
    cancelButtonText?: string;
    acceptButtonText?: string;
}) => {
    return (
        <>
            <Modal show={props.visible} onHide={() => props.onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title className={"d-flex"}>
                        {props.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.reject()}>
                        {props.cancelButtonText ?? 'No'}
                    </Button>
                    <Button variant="danger" onClick={() => props.accept()}>
                        {props.acceptButtonText ?? 'Yes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
