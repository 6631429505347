import React, {useEffect, useState} from "react";
import {saxRequest} from "../utils/sax-request";
import ReactJson from 'react-json-view'
import {Accordion, Button, Col, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import {useAppSelector} from "../../../app/hooks";
import {configStatus} from "../../../reducers/ConfigReducers";
import {requestData} from "../../../services/HTTPService";
import {authStatus} from "../../../reducers/AuthReducer";

export const SaxConnector = () => {
    const [messageHistory, setMessageHistory] = useState<any[]>([]);
    const [sessionHistory, setSessionHistory] = useState<{messages: any[]}[]>([]);
    const [connected, setConnected] = useState<boolean>(false);
    const [saxUrl, setSaxUrl] = useState<string>('');
    const [gameId, setGameId] = useState<string>('');
    const [user, setUser] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [bet, setBet] = useState<number>(0);
    const [rno, setRno] = useState<number>();
    const [authToken, setAuthToken] = useState<string>();
    const [sessionId, setSessionId] = useState<string>();
    const [availableSaxes, setAvailableSaxes] = useState<string[]>([]);
    const config =  useAppSelector(configStatus);
    const login = useAppSelector(authStatus);
    const auth = { 'Authorization': 'Bearer ' + login.loginToken }

    const sendMessage = async (action: string, message: string) => {
        try {
            const response = await requestData('POST', {message: message, sessionId: sessionId}, `${config.env.url.base}${config.env.url.saxConnectorSend}`, auth, true);
            if (response.ok) {
                const jsonResponse = await response.json();
                handleResponse(jsonResponse);
                addToHistory(jsonResponse);
            } else {
                const textResponse = await response.text();
                addToHistory({ for: action, status: "ERR", response: textResponse });
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleResponse = (response: any) => {
        if (response.for === "LOGIN" && response.authtoken !== undefined) {
            setAuthToken(response.authtoken);
        }
        if (response.rno) {
            setRno(response.rno);
        }
        if (response.curbet) {
            setBet(response.curbet);
        }
    }

    const connect = async () => {
        try {
            const response = await requestData('POST', {saxUrl: saxUrl}, `${config.env.url.base}${config.env.url.saxConnectorConnect}`, auth, true);
            const textResponse = await response.text();
            if (!response.ok) {
                console.error(textResponse);
                setSessionHistory([...sessionHistory, { messages: [{ for: "CONNECT", status: "ERR", response: textResponse}] }]);
                return;
            }
            setSessionId(textResponse);
            setConnected(true);
            setSessionHistory([...sessionHistory, { messages: [] }]);
        } catch (error) {
            console.error(error)
        }
    };

    const closeConnection = async () => {
        try {
            await requestData('POST', {sessionId: sessionId}, `${config.env.url.base}${config.env.url.saxConnectorCloseConnection}`, auth, true);
        } catch (error) {
            console.error(error)
        }
        setConnected(false);
        setMessageHistory([]);
    };

    const addToHistory = (message: any) => {
        messageHistory.push(message);
        sessionHistory[sessionHistory.length - 1]!.messages.push(message);
        setMessageHistory([...messageHistory]);
    }

    useEffect(() => {
        const fetchSaxes = async () => {
            try {
                const response = await (await requestData('GET', undefined, `${config.env.url.base}${config.env.url.saxConnectorSaxUrl}`, { 'Authorization': 'Bearer ' + login.loginToken })).json();
                setAvailableSaxes(response);
                setSaxUrl(response[0]);
            } catch (error) {
                console.error(error.error)
            }
        }
        if(config && login) {
            fetchSaxes();
        }
    }, [config, login])

    const connectionButtons = [
        {
            text: "Connect",
            disabled: connected,
            onClick: () => connect(),
            color: "primary"
        },
        {
            text: "Close connection",
            disabled: !connected,
            onClick: () => closeConnection(),
            color: "warning"
        }
    ]
    const sessionButtons = [
        {
            text: "LOGIN",
            disabled: !connected,
            onClick: () => sendMessage("LOGIN", saxRequest.getLogin(user, password)),
        },
        {
            text: "CHECK",
            disabled: !connected,
            onClick: () => sendMessage("CHECK", saxRequest.getCheck(authToken!)),
        },
        {
            text: "SELGAME",
            disabled: !connected,
            onClick: () => sendMessage("SELGAME", saxRequest.getSelgame(authToken!, gameId)),
        },
        {
            text: "QUITGAME",
            disabled: !connected,
            onClick: () => sendMessage("QUITGAME", saxRequest.getQuitgame(authToken!)),
        },
        {
            text: "LOGOUT",
            disabled: !connected,
            onClick: async () => {
                await sendMessage("LOGOUT", saxRequest.getLogout(authToken!));
                closeConnection();
            },
        }
    ];
    const actionButtons = [
        {
            text: "SPIN",
            disabled: !connected,
            onClick: () => sendMessage("SPIN", saxRequest.getSpin(authToken!, bet)),
        },
        {
            text: "GAMBLE",
            disabled: !connected,
            onClick: () => sendMessage("GAMBLE", saxRequest.getGamble(authToken!, bet, rno!)),
        },
        {
            text: "TAKE",
            disabled: !connected,
            onClick: () => sendMessage("TAKEs", saxRequest.getTake(authToken!, rno!)),
        }
    ];
    const formInputs = [
        {
            label: "Game id",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setGameId(e.target.value),
            value: gameId,
            type: "text"
        },
        {
            label: "User",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setUser(e.target.value),
            value: user,
            type: "text"
        },
        {
            label: "Password",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
            value: password,
            type: "text"
        },
        {
            label: "Bet",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setBet(Number(e.target.value)),
            value: bet,
            type: "number"
        }
    ];

    return (
        <>
            <Row>
                <Col lg={10}>
                    <div className={"m-3"}>
                        <FormGroup className={'mt-2'}>
                            <FormLabel htmlFor="basic-url">Sax url</FormLabel>
                            <Form.Select onChange={(e) => setSaxUrl(e.target.value)}>
                                {availableSaxes.map((sax, index) => {
                                    return <option key={index} value={sax}>
                                        {sax}
                                    </option>
                                })}
                            </Form.Select>
                        </FormGroup>
                        {formInputs.map((input, index) => (
                            <FormGroup key={index} className={'mt-2'}>
                                <FormLabel htmlFor={input.label}>{input.label}</FormLabel>
                                <input
                                    type={input.type}
                                    className="form-control"
                                    id={input.label}
                                    placeholder={input.label}
                                    onChange={input.onChange}
                                    value={input.value}
                                />
                            </FormGroup>
                        ))}
                    </div>
                    <div className={"m-3"}>
                        <h3>Connection status: {connected ? <span className={'text-success'}>CONNECTED</span>  : "DISCONNECTED"}</h3>
                    </div>
                </Col>
                <Col xs={12}>
                    <Row className={'m-1'}>
                            <Col xl={10} className={'sticky-top'}>
                                    <div className={"m-2"}>
                                        {connectionButtons.map((button, index) => (
                                            <Button
                                                key={index}
                                                onClick={button.onClick}
                                                className={`m-1 btn-${button.color}`}
                                                disabled={button.disabled}
                                            >
                                                {button.text}
                                            </Button>
                                        ))}
                                    </div>
                                    <div className={"m-2"}>
                                        {sessionButtons.map((button, index) => (
                                            <Button
                                                key={index}
                                                onClick={button.onClick}
                                                className={`m-1 btn-primary}`}
                                                disabled={button.disabled}
                                            >
                                                {button.text}
                                            </Button>
                                        ))}
                                    </div>
                                    <div className={"m-2"}>
                                        {actionButtons.map((button, index) => (
                                            <Button
                                                key={index}
                                                onClick={button.onClick}
                                                className={`m-1 btn-primary}`}
                                                disabled={button.disabled}
                                            >
                                                {button.text}
                                            </Button>
                                        ))}
                                    </div>
                            </Col>
                            <Col xl={10}>
                                {sessionHistory.length > 0 && (
                                    <Accordion defaultActiveKey={"0"}>
                                        {sessionHistory.map((session, sessionIndex) => (
                                            <Accordion.Item key={sessionIndex} eventKey={`${sessionIndex}`}>
                                                <Accordion.Header>{`#${sessionIndex + 1} Session`}</Accordion.Header>
                                                <Accordion.Body>
                                                    {session.messages.map((message, messageIndex) => (
                                                        <ReactJson
                                                            key={messageIndex}
                                                            src={message}
                                                            name={`${message.for} [${message.status}]`}
                                                            collapsed={true}
                                                        />
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                )}
                            </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
}
