import React, { useState, useReducer, useEffect } from 'react'; //useState

// STYLES
import { Form, Button, Alert} from 'react-bootstrap';

// IMAGES
import navbarLogo from "../../images/login/nav-bar-logo--big.png";
import loginLoader from "../../images/login/login-loader.gif";

// REDUCERS
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { authStatus } from '../../reducers/AuthReducer'; // LoginAction
import { configStatus } from '../../reducers/ConfigReducers'; // setEnvironment

// SERVICES
import { LogIn } from '../../app/accountActions';

// TYPES
import { LoginFormState } from '../../types/CommonTypes';





export function LoginPage () {

    const login = useAppSelector(authStatus);
    const config =  useAppSelector(configStatus);

    const dispatch = useAppDispatch();

    const [state, setState] = useReducer( (state: LoginFormState, newState: any) => ({...state, ...newState}), {user: '', pwd: '', casinoTag: '', provider: '', providersArray: [], errorRead: false} )
    const [validated] = useState(false); // setValidated

    useEffect(() => {

        if (config.casino.length === 1) {
            setState({casinoTag: (config.casino.length === 1) ? config.casino[0].tag : '' });
        }

        if ( state.casinoTag.length > 0) {
            let _providers = config.casino.find( (key: any) =>  key.tag === state.casinoTag  ) 
            setState({providersArray: _providers?.providers });
        }
        
    },[config, state.casinoTag ]);
    
    // 
    //     User Log in Action 
    //     Set configReducer envroment state with choosen envroment settings // "flatten" data
    // 
    
    function  logIn (evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        if ( !validated && login.status !== 'loading') {
            LogIn(dispatch, config, {casino: state.casinoTag, provider: state.provider }, { user: state.user, pwd: state.pwd })
        }
    }


    function handleChange(evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, type: string): void {
        setState({ [type]: evt.target.value})
    }


    function readErrorMessage(): void {
        setState({ errorRead: true})
    }

    return(
        <div className="login-panel--container">
            <div className="login-panel--brand">
                <img src={navbarLogo} alt="Promatic Support"/>
            </div>
            
            <div className="login-panel">

                <div className="login-panel--header">
                    <h3>Login Page</h3>
                </div>

                <Form onSubmit={(evt)=> logIn(evt) } className="login-panel--form" validated={validated} >
                    <Form.Group controlId="formLogin">
                        <Form.Label>Login { }</Form.Label>
                        <Form.Control placeholder="Login"  value={state.user} onChange={(evt)=> handleChange(evt, 'user')} required/>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={state.pwd} onChange={(evt)=> handleChange(evt, 'pwd')}  required/>
                    </Form.Group>

                    { config.casino.length === 1 ? (
                        <Form.Group controlId="formCasinoSelected">
                            <Form.Label>Casino</Form.Label>
                            <Form.Control  placeholder={config.casino[0].tag} value={config.casino[0].name} disabled required/>
                        </Form.Group>
                    ):(
                        <Form.Group controlId="formSelectCasino">
                            <Form.Label>Select Casino</Form.Label>
                            <Form.Control as="select" value={state.casinoTag} onChange={(evt) => handleChange(evt, 'casinoTag')} required>
                                <option value="">Select Casino</option>
                                { config.casino.map( (key: any, index: any) => 
                                    <option key={index} value={key.tag}>{key.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>    
                    )}   


                    {  state.casinoTag.length > 0 && 
                        <Form.Group controlId="formSelectCasino">
                            <Form.Label>Select Provider</Form.Label>
                            <Form.Control as="select" value={state.provider} onChange={(evt) => handleChange(evt, 'provider')} required>
                                <option value="">Select Provider</option>
                                { state.providersArray.map( (key: any, index: any) => 
                                    <option key={index} value={key.tag}>{key.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>    
                    }

                    <div className="login-panel--actions">
                        { login.status === 'loading' ? (
                            <img src={loginLoader} alt="Loading..." />
                        ):(
                            <>
                                <Button variant="primary" type="submit">
                                 Submit
                                </Button>
                            </>
                        )}      
       
                    </div>
                </Form>
            </div>     

            { (login.error && !state.errorRead)  && 
                <Alert variant="danger" className="message-alert message-alert--login"  onClose={() => readErrorMessage()}  dismissible>
                    <Alert.Heading>Upppss!!!</Alert.Heading>
                    <p>
                        {login.errorMessage}
                    </p>
                </Alert>
            }
            <div className="login-copy">
                COPYRIGHT &copy; 2021 WWW.PROMATIC.PL. <br/>
                ALL RIGHTS RESERVED.
            </div>   

        </div>
    ) 

};
