import {EnvBasicInformation} from '../types/CommonTypes'


export const EnvBasicDefaultInformation: EnvBasicInformation = {
    casinoName: '',
    casinoTag: '',
    provider: '',
    providerName: '',
    providerTag: '',
    url:  {
      base: '',
      cycleDetails: '',
      cycleHistory: '',
      loginApi: '',
      session: '',
      verifyTokenApi: '',
    }
  }

export enum AppRoles {
    support = 'ROLE_SUPPORT',
    saxConnector = 'ROLE_SAX_CONNECTOR',
    manageBrokenGames = 'ROLE_MANAGE_BROKEN_GAMES',
    manageGameCycles = 'ROLE_MANAGE_GAME_CYCLES',
}
