
// TYPES
import { EnvToken } from '../types/CommonTypes';


export class LocalStorageManager {

    private static instance: LocalStorageManager;

    static getInstance(): LocalStorageManager {
        if (!LocalStorageManager.instance) {
          LocalStorageManager.instance = new LocalStorageManager();
        }
        return LocalStorageManager.instance;
    }

    setItem(name: string, data: EnvToken) {
      window.localStorage.setItem(name, JSON.stringify(data));
    }

    getItem(name: string) {
      const item = window.localStorage.getItem(name) as string;
      return JSON.parse(item);
    }

    removeItem(name: string) {
      window.localStorage.removeItem(name);
    }

    clear() {
      window.localStorage.clear();
    }

 }


